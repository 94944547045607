import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home.js';
import Privacy from './Privacy.js';
import Header from './Header.js';
import Contact from './Contact.js';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<Home /> } />
        <Route path="Privacy" element={<Privacy />} />
        <Route path="Contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
};


export default App;
