
import React from "react";
import './App.css';
import Video from './img/PD_Promo_1080.mp4'


function Home() { 

    return (
    <div className='main-page'>
        <div className="container">
        <div className="left-container">
          <img src={require ('./img/img2-noback.png')} alt='PigDog' />
          <h1>PIGDOG</h1>
          </div>
          <div className="right-container">
          <video controls autoPlay loop muted width='300' height='500' style={{width: '60%', height: 'auto'}} src={Video} type='video/mp4' />
          </div>
          </div>
    </div>
    );
}
export default Home;