import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

function Header () {
    return (
      <>
      <div className="App">
        <header className="App-header">
          <ul>
            <li><Link to="/">ABOUT US</Link></li>
            <li><Link to="/privacy">PRIVACY POLICY</Link></li>
            <li><Link to="/Contact">CONTACT US</Link></li>
          </ul>
        </header>
      </div>
      </>
    );
  }
  export default Header;