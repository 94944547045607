
import React, {useRef} from "react";
//import { useState } from "react";
import './App.css';
import emailjs from '@emailjs/browser';
//new version from example 

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_sp2gdn5', 
        'template_9zfo9og', 
        form.current, {
        publicKey: 'yJdeMngK8mfIN-MVd',
      })
      .then(
        () => {
          console.log('Your email was sent!');
          alert('Your email was successfully sent!')
          e.target.reset();
        },
        (error) => {
          console.log('Error ...', error.text);
        },
      );
  };

  return (
    <div className='contact'>
      <div className="contact-head">
           <h1>Contact us</h1>
           <p>Please fill this form</p>
           </div>
      <div className="form">
    <form ref={form} onSubmit={sendEmail}>
      <label htmlFor='First-name'>Name</label> <br />
      <input type="text" name="user_name" /> <br />
      <label htmlFor='Email'>Email</label> <br />
      <input type="email" name="user_email" /><br />
      <label htmlFor='Message'>Message</label> <br />
      <textarea className='message-style' name="message" /><br />
      <button className='submit-button' type="submit">Send</button> 
    </form>
    </div>
    </div>
  );
};

export default Contact;
//old version of form 
// function Contact() { 
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         email: '',
//         message: ''
//       });

     
//       const handleSubmit = async (event) => {
//         event.preventDefault();
//         try {
//           const response = await fetch('https://pigdog.games/send-email', {
//             method: 'POST',
//             headers: {
//               'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(formData),
//           });
    
//           if (response.ok) {
//             console.log('Email sent successfully!');
//             setFormData({ firstName: '',lastName: '', email: '', message: '' }); // Очистка формы
//           } else {
//             console.log('Failed to send email.');
//           }
//         } catch (error) {
//           console.error('Error sending email:', error);
//         }
//       };
    
//       const handleChange = (event) => {
//         const { name, value } = event.target;
//         setFormData((prevState) => ({
//           ...prevState,
//           [name]: value,
//         }));
//       };

//     return (
//     <div className='contact'>
//         <div className="contact-head">
//           <h1>Contact us</h1>
//           <p>Please fill this form</p>
//           </div>
//           <div className="form">
//           <form onSubmit={handleSubmit} >
//             <label htmlFor='First-name'>First Name: </label><br />
//             <input type="text" id='First-name' name="firstName" onChange={handleChange} value={formData.firstName}/> <br />

//             <label htmlFor='Last-name'>Last Name: </label><br />
//             <input type="text" id='Last-name' name="lastName" onChange={handleChange} value={formData.lastName}/> <br />

//             <label htmlFor='E-mail'>E-mail: </label><br />
//             <input type="text" id='E-mail' name='email'onChange={handleChange} value={formData.email}/> <br />

//             <label htmlFor='Message'>Message: </label><br />
//             <textarea className='message-style' type="text" id='Message' name='message'onChange={handleChange} value={formData.message} /> <br />

//             <button className='submit-button' type="submit">Send</button> 
//           </form>
//           </div>
//     </div>
//     );
// }
// export default Contact;