
import React from "react";
import './App.css';

function Privacy() { 
    return (
    <div className="pravicy">
          <h1>Privacy Policy</h1>
          <p>PigDog built the RacingGame app as a Free app. This SERVICE is provided by PigDog at no cost and is intended for use as is. <br /> 
This page is used to inform visitors regarding our policies with the collection, use, 
and disclosure of Personal Information if anyone decided to use our Service. <br />
If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. 
The Personal Information that we collect is used for providing and improving the Service. 
We will not use or share your information with anyone except as described in this Privacy Policy. <br />
The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
which is accessible at RacingGame unless otherwise defined in this Privacy Policy. 
</p>
        <h1>Information Collection and Use</h1>
        <p>For a better experience, while using our Service, we may require you to provide us with certain personally 
identifiable information. The information that we request will be retained by us and used as described in this privacy policy. <br />
The app does use third party services that may collect information used to identify you. <br />
Link to privacy policy of third party service providers used by the app 
<ul>
<li>Google Play Services</li> 
<li>AdMob</li>
<li>Facebook</li>
<li>Appodeal</li>
</ul>
        </p>
        <h1>Personal Data that We Collect and Process </h1>
        <p>We may collect the following categories of personal data relating to our users: 
<ol>
<li>Data about your account and game progress, we create a RacingGame-specific ID for you when you use the Services.</li>
<li>Your IP address and unique mobile device identification numbers (such as your device ID, advertising ID, MAC address).</li>
<li>Data about your device, such as manufacturer, operating system, CPU, RAM, browser type and language. </li>
<li>Broad location data (e.g. country or city-level location). </li>
<li>Precise geolocation data (GPS, with your consent). </li>
<li>Usage data, such as data we collect with cookies and similar technologies.</li>
<li>Data (such as your nickname, profile picture) we receive if you link another provider’s tool with the Service (such as Facebook, Google).</li> 
<li>Purchase history, including details of orders (amount spent, date, time, vouchers or offers used).</li>
<li>Data to fight fraud (such as refund abuse in games or click fraud in advertising). </li>
<li>Data from platforms that the game runs on (such as to verify payment). </li>
<li>Data for advertising and analytics purposes, so we can provide you a better Service.</li>
<li>Your email address and your messages to the Services (such as chat logs and player support tickets sent by email or through Helpshift service (or another service we can use in the future)) any feedback you submitted about your experience with us. </li> 
<li>Other data you choose to give us.</li>
</ol>
        </p>
        <h1>How the Data is Processed and the Purposes for Which it is Processed </h1>
        <p>We may obtain your personal data directly (through the game, website or online forms) or from other entities: service providers that are assisting us in providing you with a service (including data analytics providers, operating systems, and internet services providers), 
or from your social networks accounts you used to sign in to our online services (for example, Facebook, Google). <br />
The data is processed on our private secured servers by our analytical algorithms. <br />
We may use your personal data to: 
<ol>
<li>Provide you with our products or services (for example, play one of our online games and make online purchases)</li>
<li>Establish and manage our relationship (including maintaining or servicing accounts, providing customer service, and making your experience with us personalised)</li> 
<li>Learn about how our products and services are or may be used to verify or maintain the quality of, and improve, our products and services (for example, when we ask you to fill out surveys about the experience you had with us)</li>
<li>Security (ensuring confidentiality of personal data, preventing unauthorised access and modifications to our systems and otherwise maintaining the security of personal data) </li>
<li>Perform advertising or marketing services, including letting you know about our products, services and events that may be of interest to you by email or other forms of electronic communication </li>
</ol>
        </p>
        <h1>How Long Do We Keep Your Personal Data and How it Can Be Deleted </h1>
        <p>We will process your personal data only for as long as is necessary for the purposes for which it was collected in connection with the provision of service to you, unless we have a legal right or obligation to retain the data for a longer period, or the data is necessary for the establishment, exercise or defence of legal claims. <br />
You are entitled to obtain information from us on how we handle your personal data, to see copies of all personal data held by us. You can also send request to delete those data. In order to do that please contact us via email: pigdog@gmail.com . <br />
        </p>
        <h1>Log Data </h1>
        <p>We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol <br />
(“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
        </p>
        <h1>Cookies </h1>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. <br />
This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service. <br />
        </p>
        <h1>Service Providers </h1>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>
        <ul>
<li>To facilitate our Service;</li>
<li>To provide the Service on our behalf;</li>
<li>To perform Service-related services; or </li>
<li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose. <br />
        </p>
        <h1>Security </h1>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. <br />
        </p>
        <h1>Links to Other Sites</h1>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services. <br />
        </p>
        <h1>Children’s Privacy</h1>
        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions. 
        </p>
        <h1>Changes to This Privacy Policy</h1>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
        </p>
        <h1>Contact Us </h1>
        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at pigdog@gmail.com.
        </p>
    </div>
    );
}
export default Privacy;